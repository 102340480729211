import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/manager/exp/labManager.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "labManager",
    data() {
        return {
            form:{
                id:'',
                name:'',
                package:'',
                des:'',
                article_id:'',
                helpurl:''
            },
            school_id:'',
            schoolList:[],
            // 虚拟实验分类数据
            explabList: [],
            explabListTotal: 0,
            // 根据虚拟实验分类内容搜索
            searchContent: "",
            articleList: [],
            // 当前页码数
            currentPage: 1,
            pageSize: 10,
            exp_id: '',
            dialogVisible: false,
            loading:true,
            title:''
        }
    },

    created() {
        let self = this;
        self.searchContent = this.$router.currentRoute.query.search;
        self.currentPage = Number(this.$router.currentRoute.query.page)?Number(this.$router.currentRoute.query.page):1;
        self.getData()
    },
    methods:
    {   
        showDialog()
        {
            for (const key in this.form) {
                this.form[key] = ''
            }
            this.title="添加虚拟实验分类";
            this.dialogVisible = true;
        },

        getData() {
            try {
                let self = this;
                // 请求参数
                let data = {
                    search: self.searchContent,
                    school_id:self.school_id,
                    page:self.currentPage,
                    pagesize:self.pageSize
                };
                self.$router.push({
                    path: "/manager/labManager",
                    query: {
                        page: self.currentPage,
                        search: self.searchContent,
                    },
                });
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getExpLabList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.explabList = result.logList
                            self.explabListTotal = result.number - 0
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        del(id,name) 
        {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id:id
                };
                // 删除同事请求
                self.$confirm(`确认要删除分类${name}吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    API.delExpLabById(data)
                        .then((result) => {
                            if (result.code > 0) {
                                // Message.success('删除成功');
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'success',
                                    displayTime: 1500
                                })
                            }
                            // else
                            // {
                            //     Message.error('系统错误');
                            // }
                        })
                }).catch(() => {
                    // Message.info('已取消');        
                });
            }
            catch (error) {
                // Message.error('系统错误');
            }
        },

        getExpLabDataById(id)
        {
            let self = this;
            // 请求参数
            let data = {
                id: id,
            };
            // 修改虚拟实验分类数据请求
            API.getExpLabDataById(data)
                .then((result) => {
                    if (result.code > 0) 
                    {
                        self.articleList.push({
                            id:result.info.article_id,
                            title:result.info.articleName,
                        })
                        self.form={...result.info};
                        self.title="修改虚拟实验分类"
                        self.dialogVisible = true
                    }
                    else 
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        confirmAdd() 
        {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id: self.form.id,
                    name:self.form.name,
                    // article_id:self.form.article_id,
                    // helpurl:self.form.helpurl,
                    des:self.form.des,
                };
                if(!self.form.id)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入分类id',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                if(!self.form.name)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入分类名称',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                // 添加/修改虚拟实验分类数据
                API.updateExpLabData(data)
                    .then((result) => {
                        if (result.code > 0) {
                            for (const key in self.form) {
                                self.form[key] = ''
                            }
                            self.dialogVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        getschoolList(e)
        {
            let self=this;
            // 请求参数
            let data = {
                name : e,
                page:1,
                pagesize:10
            };
            // 获取学校列表请求
            API.getSchoolList(data)
                .then((result)=>{
                    self.schoolloading = false;
                    if(result.code > 0 )
                    {
                        self.schoolList = result.list
                    }
                    // else
                    // {
                    //     Message.error('系统错误')
                    // }
                })                
        },

        getArticleList(e) 
        {
            try {
                let self = this;
                let data = {
                    search: e
                }
                if (e) {
                    API.getArticleList(data)
                        .then((result) => {
                            if (result.code > 0) {
                                self.articleList = result.articleList;
                                self.loading = false
                            }
                            else {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }
            }
            catch (error) {
                // Message.error('系统错误')
            }
        },

        handleSearchColleague() 
        {
            let self = this;
            self.currentPage = 1;
            self.getData()
        },

        updateSortBy(id,sort_no)
        {
            let self = this;
            let data = {
                id: id,
                sort:sort_no
            }
            API.updateSortBy(data)
                .then((result) => {
                    if (result.code > 0) {
                        this.getData();
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else {
                        // Message.error('系统错误')
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 虚拟实验分类表格分页序号递增
         * @author: camellia
         * @date: 2021-03-05
         */
        indexMethod(index) 
        {
            let currentPage = this.currentPage - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
    }
};