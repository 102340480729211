<template >
    <div class="labManager">
        <div class="logButton clearFix">
            <div class="export">
                <el-button class="themeButton" @click="showDialog">添加分类</el-button>
            </div>
            <div class="search">
                <!-- <div class="searchContent">
                    <el-select
                    v-model="school_id"
                    filterable
                    remote
                    clearable
                    placeholder="请搜索选择学校"
                    :remote-method="getschoolList">
                    <el-option
                        v-for="item in schoolList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                </div>  -->
                <div class="searchContent">
                    <el-input placeholder="请输入分类名称" clearable @clear="getData" v-model="searchContent" class="input-with-select" ></el-input>
                </div> 
                <el-button class="themeButton" @click="handleSearchColleague">搜索</el-button>
            </div>
        </div>
        <!-- 题目列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="explabList"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                      type="index"
                      :index="indexMethod"
                      label="序号"
                      width="100">
                    </el-table-column>
                    <!-- 序号 -->
                    <!-- <el-table-column
                      label="排序"
                      width="100">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.paixu" @blur="updateSortBy(scope.row.id,scope.row.paixu)"></el-input>
                      </template>
                    </el-table-column> -->
                    <!-- 头像 -->
                    <el-table-column
                      label="分类名称"
                      prop="name">
                    </el-table-column>
                    <el-table-column
                      label="分类ID"
                      prop="id">
                    </el-table-column>
                    <el-table-column
                      label="项目数量"
                      prop="expNumber">
                    </el-table-column>
                    <el-table-column
                      label="备注"
                      prop="des">
                    </el-table-column>
                    <!-- <el-table-column
                      label="帮助链接"
                      prop="article_id">
                    </el-table-column> -->
                    <!-- 操作-->
                    <el-table-column
                    label="操作">
                        <template slot-scope="scope">
                            <div class="operatorItem floatLeft" @click="getExpLabDataById(scope.row.id)">编辑</div>
                            <div class="operatorItem floatLeft" @click="del(scope.row.id,scope.row.name)">删除</div>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- 题目分页 -->
            <el-pagination
                @current-change="getData"
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="explabListTotal">
            </el-pagination>
        </el-card>
            <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="30%">
                <span  class="dialog-span">
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="分类id">
                            <el-input placeholder="请输入分类id" v-model="form.id"></el-input>
                        </el-form-item>
                        <el-form-item label="分类名称">
                            <el-input placeholder="请输入分类名称" v-model="form.name"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="包名">
                            <el-input placeholder="请输入包名" v-model="form.package"></el-input>
                        </el-form-item> -->
                        <el-form-item label="备注">
                            <el-input placeholder="请输入备注" v-model="form.des"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="素材">
                            <el-select
                            :disabled="form.helpurl!=''&& form.helpurl!=null"
                                v-model="form.article_id"
                                filterable
                                remote
                                clearable
                                :loading="loading"
                                placeholder="请搜索选择素材！"
                                :remote-method="getArticleList">
                                <el-option
                                    v-for="item in articleList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="帮助链接">
                            <el-input placeholder="请输入帮助链接" :disabled="form.article_id!=''&& form.article_id!=null" v-model="form.helpurl"></el-input>
                        </el-form-item> -->
                    </el-form>
                </span>
            <span
                slot="footer"
                class="dialog-footer">
                <el-button class="themecancelButton" @click="dialogVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="confirmAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  
</template>

<script>
    // 引入试题列表js
    import labManager from "@/assets/js/manager/exp/labManager.js";
    export default {
     ...labManager
    }
</script>
<style>
.labManager .el-table .el-table__cell {
    padding: .55vw 0 !important; 
}
.labManager .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/manager/exp/labManager.scss";
</style>