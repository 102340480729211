import { request } from "@/request/http";

/**
 * @name: 虚拟实验分类管理接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  // 获取虚拟实验分类列表
  getExpLabList: (data) => {
    return request("POST", `/index.php/adminExp/getExpLabList`, data);
  },
  // 获取文章列表
  getArticleList: (data) => {
    return request("POST", `/index.php/adminExp/getArticleList`, data);
  },
  // 获取学校列表
  getSchoolList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolList`, data);
  },
  // 添加/修改虚拟实验分类数据
  updateExpLabData: (data) => {
    return request("POST", `/index.php/adminExp/updateExpLabData`, data);
  },
  // 获取修改虚拟实验分类数据
  getExpLabDataById: (data) => {
    return request("POST", `/index.php/adminExp/getExpLabDataById`, data);
  },
  // 删除虚拟实验分类数据
  delExpLabById: (data) => {
    return request("POST", `/index.php/adminExp/delExpLabById`, data);
  },
  // 修改排序
  updateSortBy: (data) => {
    return request("POST", `/index.php/adminExp/updateSortBy`, data);
  },
};